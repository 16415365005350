import './App.css';
import { initializeApp } from "firebase/app";
import logo from "./assets/dimension-logo.png";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB0LDll_F61r7cOjOVq3fzM0Xb87b1nhOY",
  authDomain: "get-dimension.firebaseapp.com",
  projectId: "get-dimension",
  storageBucket: "get-dimension.appspot.com",
  messagingSenderId: "690844815364",
  appId: "1:690844815364:web:975ae39fc24e69cee36551",
  measurementId: "G-PNW319DCZW"
};

// Initialize Firebase
initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img style={{ height: 110 }} alt="Dimension Logo" src={logo} />
        <h6>
          Visit <a style={{ color: "#FFFFFF" }} href="https://getdimension.com">GetDimension.com</a> to book!
        </h6>
        <br />
        <br />
        <br />
        <p style={{ display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "12px" }}>© 2022 Dimension. All rights reserved.</p>
      </header>
    </div>
  );
}

export default App;
